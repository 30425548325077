export enum SearchPromoTypes {
  Service = 'service',
  Banner = 'ad',
}

export enum Cases {
  Likes = 'likes',
  Incognito = 'incognito',
  Search = 'search',
  Limits = 'limits',
  Stickers = 'stickers',
  Favorites = 'favorites',
  Topup = 'top-up',
  Garant = 'featured',
  Photoline = 'photoline',
  Upload = 'upload-photo',
  // Adv = 'adv', // ?
}

export interface SearchItemPromo {
  type: 'service' | 'ad'
  case?: Cases
}
