import {
  Cases,
  SearchItemPromo,
  SearchPromoTypes,
} from 'components/page/Search/Search.types'

export const buildPromoStruct = (
  type: SearchPromoTypes,
  promoCase?: Cases
): SearchItemPromo => ({
  type,
  case: promoCase,
})
