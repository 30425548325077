export const PromoCases = {
  VIP: {
    LIKES: 'likes',
    INCOGNITO: 'incognito',
    SEARCH: 'search',
    LIMITS: 'limits',
    STICKERS: 'stickers',
    FAVORITES: 'favorites',
  },
  COMMON: {
    TOPUP: 'top-up',
    FEATURED: 'featured',
    PHOTOLINE: 'photoline',
  },
  UPLOAD: 'upload-photo',
  ADV: 'adv',
}

export type PromoCase =
  | typeof PromoCases.VIP
  | typeof PromoCases.COMMON
  | typeof PromoCases.UPLOAD
  | typeof PromoCases.ADV

/**
 * Перевести на этот тип в дальшейших задачах.
 */
export type PromoType =
  | 'likes'
  | 'incognito'
  | 'search'
  | 'limits'
  | 'stickers'
  | 'favorites'
  | 'top-up'
  | 'featured'
  | 'photoline'
  | 'upload-photo'
  | 'adv'
