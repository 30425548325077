import { PROMO_ITEMS_BY_CHUNK } from 'components/page/Search/searchPromoFunctions'
import { createRandomArrayIndex } from 'functions/array'
import { getPromoService } from 'components/page/Search/function/getPromoService'
import { Cases, SearchItemPromo } from 'components/page/Search/Search.types'
import { createPromoPotentialList } from 'functions/promo/createPromoPotentialList'
import { PromoCases } from 'common-constants/promoCases'

export const createPromo = (
  vip: boolean,
  hasDefaultPhoto: boolean | null,
  authorized: boolean,
  lastPromoInsert: SearchItemPromo | {},
  isPhotolineEnabled: boolean | undefined
) => {
  if (!authorized) {
    return []
  }

  const promoIterator = createPromoIterator(
    vip,
    hasDefaultPhoto,
    isPhotolineEnabled
  )

  return promoIterator.getNextChunk(lastPromoInsert)
}

export const createPromoIterator = (
  hasActiveVip: boolean,
  hasPhoto: boolean | null,
  isPhotolineEnabled = true
) => {
  const promoListType = createPromoPotentialList(
    hasActiveVip,
    hasPhoto,
    isPhotolineEnabled,
    true
  )

  let shouldPushAdAtCurrentIteration = Boolean(Math.floor(Math.random() * 2))

  const getNextChunk = (
    lastPromoInsert: SearchItemPromo | {}
  ): SearchItemPromo[] => {
    let lastPromoInsertCurrent = lastPromoInsert
    const promoListArray = [...promoListType]
    const chunk = [] as SearchItemPromo[]

    while (chunk.length < PROMO_ITEMS_BY_CHUNK) {
      const currentPromoInsert = generateRandomPromoElement(
        filterPromosList(
          promoListArray as Cases[],
          lastPromoInsertCurrent as SearchItemPromo
        )
      )
      chunk.push(currentPromoInsert)
      lastPromoInsertCurrent = currentPromoInsert

      shouldPushAdAtCurrentIteration = !shouldPushAdAtCurrentIteration
    }

    return chunk
  }

  return {
    getNextChunk,
  }
}

export const filterPromosList = (
  array: Cases[],
  lastPromoInsert: SearchItemPromo
) => {
  if (
    lastPromoInsert.case &&
    PromoCases.VIP[lastPromoInsert.case?.toUpperCase()]
  ) {
    return array.filter(
      (item: Cases) =>
        item !== lastPromoInsert.case &&
        !Object.values(PromoCases.VIP).includes(item)
    )
  }

  return array.filter((item: Cases) => item !== lastPromoInsert.case)
}

export const generateRandomPromoElement = (array: Cases[]) =>
  getPromoService(array[createRandomArrayIndex(array)])
