import { PromoCase, PromoCases } from 'common-constants/promoCases'
import { shuffle } from 'functions/array'

const commonCases = Object.values(PromoCases.COMMON)
export const vipCases = Object.values(PromoCases.VIP)

export const createPromoPotentialList = (
  hasVip: boolean,
  hasDefaultPhoto: boolean | null,
  isPhotolineEnabled: boolean | undefined,
  isSearchRemoveAdv?: boolean,
  isBannerEnabled?: boolean
): PromoCase[] => {
  const _commonCases = isPhotolineEnabled
    ? commonCases
    : commonCases.filter((name) => name !== 'photoline')

  const vipValues = findVipPromo(hasVip, isSearchRemoveAdv, isBannerEnabled)
  const photoValues = !hasDefaultPhoto ? [PromoCases.UPLOAD] : []

  return shuffle([..._commonCases, ...vipValues, ...photoValues]) as PromoCase[]
  /** Для отладки: выбрать нужный тип баннера */
  // const shuffledArray = shuffle([
  //   ..._commonCases,
  //   ..._commonCases,
  //   ..._commonCases,
  //   ..._commonCases,
  //   ...photoValues,
  // ]) as PromoCase[]

  // console.log({ shuffledArray })

  // return shuffledArray
}

const findVipPromo = (
  hasVip: boolean,
  isSearchRemoveAdv?: boolean,
  isBannerEnabled?: boolean
) => {
  if (hasVip) {
    return []
  } else {
    if (isSearchRemoveAdv || !isBannerEnabled) {
      return [...vipCases]
    }

    return [...vipCases, PromoCases.ADV]
  }
}
