import { notEmptyArray } from 'functions/array'

/**
 * Отношение высоты к ширине изображения в карточке.
 * Однозначно установлено в макете.
 */
export const IMAGE_RATIO = 1.33

export const PROMO_ITEMS_BY_CHUNK = 4
export const DEFAULT_SEARCH_LIMIT = 60 - PROMO_ITEMS_BY_CHUNK
export const PROMO_INTERVAL = Math.floor(
  DEFAULT_SEARCH_LIMIT / PROMO_ITEMS_BY_CHUNK
)

export const PHOTO_SIZES = {
  SQUARE_SMALL: {
    ID: 'square_small',
    SIZE: 150,
  },
  SQUARE: {
    ID: 'square',
    SIZE: 300,
  },
  SQUARE_LARGE: {
    ID: 'squareLarge',
    SIZE: 600,
  },
}

export const insertPromoToListChunk = (
  listChunk,
  promosList,
  isFirstInject
) => {
  let promoPointer = 0

  if (isFirstInject) {
    const result = listChunk.map((item, index) => {
      if (index % PROMO_INTERVAL === 0 && index !== 0) {
        const promo = promosList[promoPointer]
        promoPointer++
        return promo
      }

      return item
    })

    return {
      result,
      lastPromoInsert: promosList[promoPointer],
    }
  }

  const result = listChunk.map((item, index) => {
    if (index % PROMO_INTERVAL === 0) {
      const promo = promosList[promoPointer]
      promoPointer++
      return promo
    }

    return item
  })
  return {
    result,
    lastPromoInsert: promosList[promoPointer - 1],
  }
}

export const injectPromosToListChunk = (
  listChunk,
  promos = [],
  currentPromoInsert,
  isFirstInject = false
) => {
  if (notEmptyArray(promos)) {
    const { result, lastPromoInsert } = insertPromoToListChunk(
      listChunk,
      promos,
      isFirstInject
    )
    return {
      searchItems: result,
      lastPromoInsert,
    }
  }

  return { searchItems: listChunk }
}

export const mapFaceCoordsToItems = (items, faceCoords) => {
  const faceCoordsMap = faceCoords.reduce((resultMap, coordsItem) => {
    resultMap[coordsItem.photoId] = coordsItem.faces
    return resultMap
  }, {})

  items.forEach((item) => {
    item.userpic.coords = faceCoordsMap[item.userpic.id]
  })

  return items
}

export const computePhotoShift = (userpic) => {
  /**
   * Часть фото скрытая контейнером, в процентах.
   * Соотвественно является максимально возможным сдвигом.
   */
  const maxShiftPercent = IMAGE_RATIO * 100 - 100

  /**
   * Процент сдвига при котором фото спозиционируется по левой границе контейнера
   * Фото изначально позиционируется по левой границе контейнера, поэтому 0.
   */
  const containerLeftBorderPercent = 0

  /**
   * Процент сдвига при котором фото спозиционируется по правой границе контейнера.
   * Соответствует максимально возможному сдвигу.
   */
  const containerRightBorderPercent = maxShiftPercent

  /**
   * Процент сдвига при котором фото спозиционируется по центру контейнера.
   */
  const containerCenterPercent = containerRightBorderPercent / 2

  const dX =
    userpic.coords && userpic.coords[PHOTO_SIZES.SQUARE.ID]
      ? userpic.coords[PHOTO_SIZES.SQUARE.ID].x
      : undefined

  /**
   * Если нет координат, то позиционируем фото по середине контейнера.
   */
  if (dX === undefined) {
    return containerCenterPercent
  }

  /**
   * Считаем сдвиг по пропорции dX / photo_size = wantedShiftPercent / maxShiftPercent
   */
  const wantedShiftPercent = (maxShiftPercent * dX) / PHOTO_SIZES.SQUARE.SIZE

  /**
   * Проверяем умещается ли вычисленный сдвиг в возможный диапазон, и если нет,
   * то позиционируем по одной из границ контейнера.
   */
  if (wantedShiftPercent > containerRightBorderPercent) {
    return containerRightBorderPercent
  } else if (wantedShiftPercent < containerLeftBorderPercent) {
    return containerLeftBorderPercent
  } else {
    return wantedShiftPercent
  }
}
