import { fetchUniNoticeAction } from 'actions/uninotice/uniNoticeAction'
import {
  advancedSearchRestrictedBackendId,
  emptySearchResultNoticeId,
} from 'components/uninotice/uniNoticeIdList'
import { simpleSearchApi } from 'api/mainSearchApi'
import { AsyncThunkAction } from 'actions/types'
import { Cursor } from 'api/search/search.types'
import isEmpty from 'functions/isEmpty'
import { createPromo } from 'components/page/Search/function/createPromo'
import { toggleInlineNoticeAction } from 'actions/uninotice/toggleInlineNoticeAction'
import { insertFirstPromoAction } from './insertFirstPromoAction'
import { SearchItemPromo } from 'components/page/Search/Search.types'
import { Api6Error } from 'api/api.types'
import { DeprecatedApi6Error } from 'api/types'
import { AsyncAction } from 'actions/actions.types'
import { SearchItem } from 'reducers/search/MainSearchState'

export const INSERT_FIRST_PROMO_SEARCH_LIST = 'INSERT_FIRST_PROMO_SEARCH_LIST' as const

export const GET_SEARCH_LIST = 'GET_SEARCH_LIST' as const

export interface GetSearchListAction
  extends AsyncAction<{
    items: SearchItem[]
  }> {
  type: typeof GET_SEARCH_LIST
  promos?: []
  cursor: Cursor
}

export interface InsertFirstPromoAction {
  type: typeof INSERT_FIRST_PROMO_SEARCH_LIST
}

export type SearchListProfileTypes =
  | GetSearchListAction
  | InsertFirstPromoAction

export const getSearchListAction = (limit: number): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  const {
    mainSearchReducer: {
      searchResult: { cursor, lastPromoInsert },
    },
    authorizationReducer: { authorized },
    userReducer: { vip, hasDefaultPhoto },
    systemReducer: { services },
  } = getState()
  if (cursor) {
    dispatch(toggleInlineNoticeAction(emptySearchResultNoticeId, false))
    if (isEmpty(lastPromoInsert) && authorized) {
      dispatch(insertFirstPromoAction())
    }

    const {
      mainSearchReducer: {
        searchResult: { cursor, lastPromoInsert: updatedLastPromoInsert },
      },
    } = getState()

    const promos = createPromo(
      vip,
      hasDefaultPhoto,
      authorized,
      updatedLastPromoInsert,
      services?.photoline?.enabled
    )

    const { result: json } = ((await dispatch(
      getSearchListPlainAction(limit, cursor, promos)
    )) as unknown) as { result: Api6Error & DeprecatedApi6Error }

    if (
      json.code &&
      json.internalError &&
      json.internalError.code === advancedSearchRestrictedBackendId
    ) {
      dispatch(fetchUniNoticeAction(emptySearchResultNoticeId))
    }
  }
}

export const getSearchListPlainAction = (
  limit: number,
  cursor: Cursor,
  promos: SearchItemPromo[]
) => ({
  type: GET_SEARCH_LIST,
  promise: () => simpleSearchApi(limit, cursor),
  promos,
  cursor,
})
